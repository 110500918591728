import { Box, Button, Grid, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Loader from "pages/build-test/loader";
import { Link, useHistory } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import NoDataFound from "shared/molecules/no-data";
import { TypographyHeading } from "shared/molecules/typography-layout";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { StudentCourseLookupRequest } from "pages/configue/slices/student-course-slice";
import {
  UpdatefeatureConfigDetails,
  emptyConfigData,
  featureConfigRequest,
} from "pages/configue/slices/feature-config-slice";
import { table } from "./helper";
import configueService from "pages/configue/service";
import { FeatureConfigFilter } from "./fc-filter/FeatureConfigueFilter";
import { isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import {
  checkFeatureAccess,
  plateformConfigueUser,
} from "utils/role-restriction/helper";
const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export const FeatureConfigue = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [feConfig, setFeConfig] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchPayload, setSearchPayload] = useState({});
  const history = useHistory();
  const { data, error, loading } = useAppSelector(
    (state) => state.featureConfigApiSlice
  );
  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");

  useEffect(() => {
    dispatch(featureConfigRequest({ ...searchPayload, limit, offset }));
  }, [offset, searchPayload]);

  const handleEdit = async (configId) => {
    const res: any = await configueService.getSingleConfigListApi({
      config_id: configId?.id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdatefeatureConfigDetails(res?.data?.data?.[0]));
      history.push(`/configue/feature_configue/edit/${configId?.id}`);
    }
  };

  const handleCopy = async (configId) => {
    const res: any = await configueService.getSingleConfigListApi({
      config_id: configId,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdatefeatureConfigDetails(res?.data?.data?.[0]));
      history.push(`/configue/feature_configue/duplicate/${configId}`);
    }
  };

  const handleDelete = async (configId: any) => {
    const res: any = await configueService.deleteConfigue({
      config_id: configId,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "deleted successfully" }));
      dispatch(featureConfigRequest({ ...searchPayload, limit, offset }));
    } else {
      dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  };

  const takeRequiredValue = (classList, key) => {
    const classNameList = classList?.map((SingleClass) => {
      return SingleClass[`${key}`];
    });
    return classNameList;
  };

  const convertDataToTableData = () => {
    const tableData = data.map((item, i) => {
      let singleConfig = { ...item };
      singleConfig["class_list"] = takeRequiredValue(
        item?.class_list,
        "class_name"
      );
      singleConfig["academic_year_list"] = takeRequiredValue(
        item?.academic_year_list,
        "year_name"
        // "academic_year_name"
      );
      singleConfig["batch_list"] = takeRequiredValue(
        item?.batch_list,
        "batch_name"
      );
      singleConfig["college_list"] = takeRequiredValue(
        item?.college_list,
        "college_name"
      );
      singleConfig["registration_status_list"] = takeRequiredValue(
        item?.registration_status_list,
        "registration_status_name"
      );
      singleConfig["sub_batch_list"] = takeRequiredValue(
        item?.sub_batch_list,
        "sub_batch_name"
      );
      return singleConfig;
    });
    setFeConfig(tableData);
  };

  useEffect(() => {
    if (data.length > 0) {
      convertDataToTableData();
    } else if (isEmpty(data)) {
      setFeConfig([]);
    }
  }, [data]);

  const hasModificationAccess = checkFeatureAccess(
    userRole,
    plateformConfigueUser?.FEATURE_CONFIGUE_READ_WRITE
  );

  return (
    <div>
      <ContentRight>
        {hasModificationAccess && (
          <Link
            to="/configue/feature_configue/create"
            className={classes.label}
          >
            <Button variant="contained">Create New Config</Button>
          </Link>
        )}
      </ContentRight>
      <TypographyHeading>Configue</TypographyHeading>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <FeatureConfigFilter
            setSearchPayload={setSearchPayload}
            setOffset={setOffset}
          />
        </Box>
        {loading && <Loader />}
        {!loading && feConfig.length > 0 && !error ? (
          <CollapsibleTable
            data={feConfig}
            table={table}
            id="id"
            handleEdit={hasModificationAccess ? handleEdit : ""}
            handleCopy={hasModificationAccess ? handleCopy : ""}
            handleDelete={hasModificationAccess ? handleDelete : ""}
            rowsPerPageCount={10}
            paginationSegmentApi="false"
            hasPageApiCall={false}
            showPaginationFooter={false}
          />
        ) : (
          <div>
            {!loading && (
              <NoDataFound message=" Please Search for Feature Configue List" />
            )}
          </div>
        )}
        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
};
