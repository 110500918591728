import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  successMsg: any;
  updateCourse: any;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  successMsg: {},
  updateCourse: {},
  filtersData: {},
};

const createCourseSlice = createSlice({
  name: "CreateCourseProperties",
  initialState,
  reducers: {
    courseListRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      // state.filtersData = action.payload;
    },
    courseListSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    UpdateSingleCourseDetails(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.updateCourse = action.payload;
    },
    emptyCourseListData(state: IInitState) {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  courseListRequest,
  courseListSuccess,
  UpdateSingleCourseDetails,
  emptyCourseListData,
} = createCourseSlice.actions;
export default createCourseSlice.reducer;
