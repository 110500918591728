import { Switch, Route } from "react-router-dom";
import { ForceUpdate } from "./routes/force-update";
import { UpdateVersion } from "./routes/force-update/UpdateVersion";
import { StudentCourseLookUp } from "./routes/student-Course-lookup";
import { CreateStdCourse } from "./routes/student-Course-lookup/CreateStudentCourse";
import { FeatureConfigue } from "./routes/feature-configue";
import { CreateConfig } from "./routes/feature-configue/CreateConfig";
import { StudentProgramLookUp } from "./routes/student-program-lookup";
import { CreateStdProgram } from "./routes/student-program-lookup/CreateStudentProgram";
import { UpdateStdProgram } from "./routes/student-program-lookup/UpdateStudentProgram";
import { UpdateConfig } from "./routes/feature-configue/UpdateConfig";
import { UpdateStdCourse } from "./routes/student-Course-lookup/UpdateStudentCourse";
import { CreateCourseMain } from "./routes/create-course";
import { CreateProgramMain } from "./routes/create-program";
import { CreateCourse } from "./routes/create-course/create";
import { UpdateCourse } from "./routes/create-course/update";
import { CreateProgram } from "./routes/create-program/create";
import { UpdateProgram } from "./routes/create-program/update";
import { useAppSelector } from "hooks/redux";
import { platformConfigueRoutes } from "./routes";
import { checkFeatureAccess } from "utils/role-restriction/helper";

export function ConfiguePage({ match }: { match: any }) {
  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const { role: userRoleStr } = profileData;
  const userRole = userRoleStr?.split(",");

  return (
    <>
      <Switch>
        {platformConfigueRoutes.map((route, i) => {
          return (
            checkFeatureAccess(userRole, route?.user) && (
              <Route
                key={i}
                exact
                path={`${match.url}${route.path}`}
                component={route.component}
              />
            )
          );
        })}
      </Switch>
    </>
  );
}
