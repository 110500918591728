import { Button, Grid, Paper } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";
import {
  createConfigInitialValues,
  createConfigProperties,
  createConfigValidationSchema,
} from "./helper";
import ContentRight from "shared/molecules/content-right";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getCollegeListData } from "utils/metadata-split/metaDataApiFetch";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import {
  getBatchFromClassId,
  getSubBatchFromBatchId,
} from "utils/metadata-split/depedentApiFetch";
import { featureConfigRequest } from "pages/configue/slices/feature-config-slice";
import {
  areArraysEqual,
  hasLengthGreaterThanZero,
  setReactFormValues,
} from "pages/configue/constants";

export const FeatureConfigFilter = ({ setSearchPayload, setOffset }) => {
  const [triggerSetValue, setTriggerSetValue] = useState(false);
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createConfigInitialValues,
    createConfigValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const convertArrayToString = (arr) => {
    const params = {};
    let payloadString = "";
    if (typeof arr == "string") {
      return arr;
    } else if (arr?.length > 0) {
      arr.forEach((sateid, i) => {
        if (i == arr.length - 1) {
          payloadString = payloadString + sateid;
        } else {
          payloadString = payloadString + sateid + ",";
        }
      });
      return payloadString;
    }
  };

  const clearFilter = () => {
    setValue("academic_year_list", []);
    setValue("reservation_status_list", []);
    setValue("class_list", []);
    setValue("batch_list", []);
    setValue("sub_batch_list", []);
    setValue("college_list", []);
    setValue("platform_list", []);
    setValue("boolean_operation", "");

    setSearchPayload({});
    setOffset(0);
  };
  const handleSearch = () => {
    const payload = {
      academic_year: convertArrayToString(watch()?.academic_year_list),
      reservation_status: convertArrayToString(
        watch()?.reservation_status_list
      ),
      class: convertArrayToString(watch()?.class_list),
      batch: convertArrayToString(watch()?.batch_list),
      sub_batch: convertArrayToString(watch()?.sub_batch_list),
      college: convertArrayToString(watch()?.college_list),
      platform_list: watch()?.platform_list,
      boolean_operation: watch()?.boolean_operation == "AND" ? "AND" : "OR",
    };

    setSearchPayload(payload);
    setOffset(0);
  };
  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
    if (metaKey == "sub_batch_list" && listData?.length == 0) {
      setValue("sub_batch_list", []);
    }
    if (metaKey == "batch_list" && listData?.length == 0) {
      setValue("batch_list", []);
      setValue("sub_batch_list", []);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      let res = await getCollegeListData("", {});
      if (res) {
        updateMetaData(res[0], res[1]);
      } else {
        updateMetaData([], "college_list");
      }
    }, 3000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getBatchFromClassId(watch()?.class_list, updateMetaData);
    }, 2000);
  }, [watch()?.class_list]);

  useEffect(() => {
    setTimeout(() => {
      getSubBatchFromBatchId(watch()?.batch_list, updateMetaData);
    }, 1000);
  }, [watch()?.batch_list]);

  useEffect(() => {
    if (metaData?.batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "batch_list",
        watch()?.batch_list,
        "batch_id"
      );
      let isEqual = areArraysEqual(watch()?.batch_list, matchedVales);
      if (!isEqual) {
        setValue("batch_list", matchedVales);
      }
    }
  }, [metaData?.batch_list, triggerSetValue]);

  useEffect(() => {
    if (metaData?.sub_batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "sub_batch_list",
        watch()?.sub_batch_list,
        "sub_batch_id"
      );
      let isEqual = areArraysEqual(watch()?.sub_batch_list, matchedVales);
      if (!isEqual) {
        setValue("sub_batch_list", matchedVales);
      }
    }
  }, [metaData?.sub_batch_list]);

  useEffect(() => {
    setTimeout(() => {
      setTriggerSetValue(true);
    }, 2000);
  }, []);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createConfigProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <Button
              variant="contained"
              sx={{
                width: "80px",
                height: "40px",
                marginTop: "15px",
                marginRight: "10px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={clearFilter}
              disabled={hasLengthGreaterThanZero(watch(), "boolean_operation")}
            >
              clear
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "80px",
                height: "40px",
                marginTop: "15px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleSearch}
              disabled={hasLengthGreaterThanZero(watch(), "boolean_operation")}
            >
              search
            </Button>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
