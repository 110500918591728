import useReactHookForm from "hooks/useReactHookForm";

import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { useEffect, useState } from "react";
import {
  getBatchFromClassId,
  getCollegeFromStateId,
  getSubBatchFromBatchId,
} from "utils/metadata-split/depedentApiFetch";
import configueService from "pages/configue/service";
import { useHistory } from "react-router-dom";
import {
  createConfigInitialValues,
  createConfigMandatoryFields,
  createConfigProperties,
  createConfigValidationSchema,
} from "./helper";
import { getCollegeListData } from "utils/metadata-split/metaDataApiFetch";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { areArraysEqual, setReactFormValues } from "pages/configue/constants";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
const initialJsonData = {
  home: {
    disable_learn: false,
    disable_timetable: false,
    disable_achievements: false,
    disable_practice: false,
    disable_tests: false,
    disable_revise_now: false,
    disable_filo: false,
    disable_warmup_tests: false,
    disable_trending_now: false,
    disable_counselling: false,
    disable_course_switch: false,
    disable_fun_zone: false,
    disable_fun_scrolls: false,
    disable_e_chronicle: false,
    disable_activities: false,
    disable_interactive_scrolls: false,
  },
  test: {
    disable_missed: false,
    disable_analysis_answerkey: false,
    disable_analysis_topic: false,
    disable_analysis_videos: false,
    disable_analysis_behaviour: false,
    disable_analysis_skill: false,
  },
  learn: {
    disable_videos: false,
    disable_qa: false,
    disable_summary: false,
    disable_materials: false,
  },
  library: {},
  profile: {},
  practice: {
    disable_practice_tests: false,
    disable_multi_chapter: false,
    disable_subject_practice: false,
    disable_revise_now: false,
  },
  schedule: {
    disable_school_schedule: false,
    disable_central_schedule: false,
  },
  analytics: {
    disable_progress: false,
    disable_progress_learn: false,
    disable_progress_doubts: false,
    disable_progress_practice: false,
    disable_performance: false,
    disable_progress_assignments: false,
    disable_progress_liveclass: false,
  },
  term_exam: {},
  activities: {
    disable_fun_scrolls: false,
    disable_fun_zone: false,
    disable_e_chronicle: false,
    disable_interactive_scrolls: false,
  },
  assignment: {},
  live_class: { disable_recorded: false },
  achievements: {
    disable_tests: false,
    disable_streak: false,
    disable_practice: false,
    disable_learn: false,
  },
  doubt_solving: {},
  disable_doubts: false,
  disable_library: false,
  disable_fun_zone: false,
  disable_activities: false,
  disable_term_exams: false,
  disable_assignments: false,
  disable_report_card: false,
  disable_achievements: false,
};

export const CreateConfig = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createConfigInitialValues,
    createConfigValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );
  const [jsonData, setJsonData] = useState(initialJsonData);
  const [triggerSetValue, setTriggerSetValue] = useState(false);

  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
    if (metaKey == "sub_batch_list" && listData?.length == 0) {
      setValue("sub_batch_list", []);
    }
    if (metaKey == "batch_list" && listData?.length == 0) {
      setValue("batch_list", []);
      setValue("sub_batch_list", []);
    }
  };

  const handleCreateConfig = async () => {
    const reqBody = {
      id: "id",
      academic_year_list: watch()?.academic_year_list,
      registration_status_list: watch()?.reservation_status_list,
      class_list: watch()?.class_list,
      batch_list: watch()?.batch_list,
      sub_batch_list: watch()?.sub_batch_list,
      college_list: watch()?.college_list,
      platform_list: watch()?.platform_list,
      is_global_config: watch()?.is_global_config == "true" ? true : false,
      is_early_bird_config:
        watch()?.is_early_bird_config == "true" ? true : false,
      config_json: jsonData,
    };
    const res: any = await configueService.createConfigApi(reqBody);
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "created successfully" }));
      history.replace(`/configue/feature_configue`);
    } else {
      dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  };

  const handleJsonChange = (e) => {
    if (e.jsObject) {
      setJsonData(e.jsObject);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      let res = await getCollegeListData("", {});
      if (res) {
        updateMetaData(res[0], res[1]);
      } else {
        updateMetaData([], "college_list");
      }
    }, 3000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getBatchFromClassId(watch()?.class_list, updateMetaData);
    }, 2000);
  }, [watch()?.class_list]);

  useEffect(() => {
    setTimeout(() => {
      getSubBatchFromBatchId(watch()?.batch_list, updateMetaData);
    }, 1000);
  }, [watch()?.batch_list]);

  useEffect(() => {
    if (metaData?.batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "batch_list",
        watch()?.batch_list,
        "batch_id"
      );
      let isEqual = areArraysEqual(watch()?.batch_list, matchedVales);
      if (!isEqual) {
        setValue("batch_list", matchedVales);
      }
    }
  }, [metaData?.batch_list, triggerSetValue]);

  useEffect(() => {
    if (metaData?.sub_batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "sub_batch_list",
        watch()?.sub_batch_list,
        "sub_batch_id"
      );
      let isEqual = areArraysEqual(watch()?.sub_batch_list, matchedVales);
      if (!isEqual) {
        setValue("sub_batch_list", matchedVales);
      }
    }
  }, [metaData?.sub_batch_list]);

  useEffect(() => {
    if (watch()?.is_global_config == "true") {
      setValue("is_early_bird_config", "false");
    }
  }, [watch()?.is_global_config]);

  // useEffect(()=>{
  //   if(metaData?.college_list?.length>0 && !isLoad && triggerSetValue){
  //    let matchedVales=setReactFormValues(metaData,"college_list",watch()?.college_list,"college_id")
  //       let isEqual = areArraysEqual(watch()?.college_list,matchedVales)
  //       if(!isEqual){
  //             setValue("college_list",matchedVales);
  //       }
  //   }
  // },[metaData?.college_list])

  useEffect(() => {
    setTimeout(() => {
      setTriggerSetValue(true);
    }, 2000);
  }, []);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Create New Config
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createConfigProperties(watch())}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <Box sx={{ padding: "20px" }}>
          <JSONInput
            id="json-editor"
            placeholder={jsonData}
            locale={locale}
            height="550px"
            width="800px"
            onChange={handleJsonChange}
            style={{
              outerBox: { fontSize: "40px" },
              container: { fontSize: "40px" },
              text: { fontSize: "40px" },
            }}
          />
        </Box>

        <ContentRight sx={{ mt: -7 }}>
          <Button
            variant="contained"
            sx={{
              width: "80px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={handleCreateConfig}
            disabled={
              !isFieldsValidate(errors, createConfigMandatoryFields, getValues)
            }
          >
            submit
          </Button>
        </ContentRight>
      </Paper>
    </div>
  );
};
