import Tooltip from "@mui/material/Tooltip";
import { ActionButtons } from "shared/molecules/action-buttons";
import { MultiAcitonButtons } from "shared/molecules/multi-action-buttons";
import TableCell from "@mui/material/TableCell";
import Eclipse from "shared/molecules/eclipse";
import { DOMAIN } from "utils/helpers";
import { getTime } from "utils/time-util/getTime";
import { useStyles } from "./styles";

function TableData(props: any) {
  const {
    data,
    handleEdit,
    handleDelete,
    handleCopy,
    row,
    id,
    name,
    handleStore,
    handleDeliver,
    handleAnswer,
    handlePreview,
    addUrlNavigation,
    viewPdf,
    viewSolutionPdf,
    viewVideoPdf,
    viewPdfUrl,
    viewSolutionPdfUrl,
    viewVideoPdfUrl,
    previewData,
    handlePdf,
    showPdf,
    viewAudioFile,
    viewAudioFileUrl,
  } = props;

  const styles = useStyles();
  
  const handlePdfViewer = (data, item) => {
    if (item.value === "pdf_name") {
      let pdfName = data.exam_name || data.batch_name;
      // ? Load pdf in new Tab
      const arr = data.pdf_url || data.url.split("/");
      const bckt = arr[2]?.split(".")[0];
      const rem = data.pdf_url || data.url.split(".amazonaws.com/")[1];
      window.open(
        `${DOMAIN}/pdf-viewer/${bckt}\`~\`${rem}\`~\`${pdfName}\`~\`${
          data.delivery_count || data.week_number || 0
        }`,
        "_blank"
      );
    } else {
      let pdfName = data.assignment_name || data.subject_name || data.exam_name;
      // ? Load pdf in new Tab
      const arr = data[item.value] && data[item.value].split("/");
      const bckt = arr[2]?.split(".")[0];
      const rem =
        data[item.value] && data[item.value].split(".amazonaws.com/")[1];
      window.open(
        `${DOMAIN}/pdf-viewer/${bckt}\`~\`${rem}\`~\`${pdfName}\`~\`${
          data.question_count || 0
        }`,
        "_blank"
      );
    }
  };
  return (
    <>
      {data &&
        data.map((item: any, idx) => {
          if (item.title === "Actions") {
            return (
              <TableCell key={idx} width={item.width}>
                <ActionButtons
                  handleEdit={() => handleEdit(row)}
                  hideEdit={typeof handleEdit !== "function"}
                  handleDelete={() => handleDelete(row[id])}
                  hideDeleteIcon={typeof handleDelete !== "function"}
                  handleCopy={() => handleCopy(row[id])}
                  hideCopyIcon={typeof handleCopy !== "function"}
                  handlePreview={() => handlePreview(row[id])}
                  viewPdfUrl={() => viewPdfUrl(row)}
                  viewSolutionPdfUrl={() => viewSolutionPdfUrl(row)}
                  viewVideoPdfUrl={() => viewVideoPdfUrl(row)}
                  viewPdf={viewPdf}
                  viewSolutionPdf={viewSolutionPdf}
                  viewVideoPdf={viewVideoPdf}
                  previewData={previewData}
                  handlePdf={() => handlePdf(row)}
                  showPdf={showPdf}
                  viewAudioFile={viewAudioFile}
                  viewAudioFileUrl={() => viewAudioFileUrl(row)}
                />
              </TableCell>
            );
          } else if (item.title === "MutliActions") {
            return (
              <TableCell key={idx} width={item.width}>
                <MultiAcitonButtons
                  test={item}
                  handleDeliver={() => handleDeliver(row[id], row[name])}
                  handleAnswer={() => handleAnswer(row[id])}
                  handleEdit={() => handleEdit(row[id], row["test_created_source"])}
                  handlePreview={() => handlePreview(row[id])}
                  handleStore={() => handleStore(row)}
                  addUrlNavigation={() => addUrlNavigation(row)}
                />
              </TableCell>
            );
          } else if (
            (item.title.includes("Date") || item.value.includes("date")) &&
            !item.name
          ) {
            return (
              <TableCell>
                {item?.visibleStatus
                  ? row[item.visibleStatus]
                    ? getTime.date(row[item.value], "dd-mm-yy")
                    : "-"
                  : getTime.date(row[item.value], "dd-mm-yy")}
              </TableCell>
            );
          } else if (item.name) {
            return <TableCell>{row[item.value] || "-"}</TableCell>;
          } else if (item.value && item.icon && item.inValue) {
            return (
              <TableCell key={row[item.value][item.inValue] + idx}>
                <Tooltip
                  title={item.tooltip ? row[item.value][item.tooltip] : ""}
                >
                  {row[item.value][item.inValue] ? (
                    <a
                      target="_blank"
                      href={row[item.value][item.inValue]}
                      rel="noreferrer"
                      style={{ color: "blue" }}
                    >
                      {item.icon}
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </Tooltip>
              </TableCell>
            );
          } else if (
            item.value &&
            item.icon &&
            !item.inValue &&
            item.fileType === "video"
          ) {
            return (
              <TableCell key={row[item.value] + idx}>
                <Tooltip title={item.tooltip ? row[item.tooltip] : ""}>
                  {row[item.value] ? (
                    <a
                      target="_blank"
                      href={row[item.value]}
                      rel="noreferrer"
                      style={{ color: "blue" }}
                    >
                      {item.icon}
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </Tooltip>
              </TableCell>
            );
          } else if (
            item.value &&
            item.icon &&
            !item.inValue &&
            item.fileType !== "video"
          ) {
            return (
              <TableCell key={row[item.value] + idx}>
                <Tooltip title={item.tooltip ? row[item.tooltip] : ""}>
                  {row[item.value] ? (
                    <div
                      onClick={() => handlePdfViewer(row, item)}
                      style={{ color: "black", cursor: "pointer" }}
                    >
                      {item.icon}
                    </div>
                  ) : (
                    <span>-</span>
                  )}
                </Tooltip>
              </TableCell>
            );
          } else if (item.value && !item.icon && item.inValue) {
            return (
              <TableCell key={row[item.value][item.inValue] + idx}>
                <Eclipse
                  item={
                    row[item.value][item.inValue]
                      ? row[item.value][item.inValue].toString()
                      : "-"
                  }
                />
              </TableCell>
            );
          } else if (item.value != "" && !item.icon && !item.inValue) {
            return (
              <TableCell key={`${Math.random()}-${idx}`} width={item.width}>
                {typeof row[item.value] === "boolean" ? (
                  row[item.value].toString()
                ) : typeof row[item.value] === "number" ? (
                  row[item.value] || "-"
                ) : (
                  <Eclipse
                    item={
                      // row[item.visibleStatus]?row[item.value]:
                      Array.isArray(row[item.value])
                        ? row[item.value] && row[item.value].length > 0
                          ? String(row[item.value] || "-")
                          : "-"
                        : row[item.value]
                        ? row[item.value].toString() ||
                          String(row[item.value] || "-")
                        : String(row[item.value]) || "-"
                    }
                    isConfigueType={item?.type=="configue"?true:false}
                  />
                )}
              </TableCell>
            );
          } else {
            <TableCell>No Data</TableCell>;
          }
        })}
    </>
  );
}

export default TableData;
