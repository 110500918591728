import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NavItem from "shared/atoms/nav-item";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { StyledListItemButton, useStyles } from "./styles";
import { useAppSelector } from "hooks/redux";
import { checkFeatureAccess } from "utils/role-restriction/helper";

export default function NavCollapse(props: any) {
  const { navItemData, panelStatus } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
  const {role:userRoleStr}=profileData
  const userRole = userRoleStr?.split(",");

  const handleToggle = () => {
    setOpen(!open);
  };

  const checkUrl = (pathname: string) => {
    if (isUrlInChildren(navItemData, pathname)) setOpen(true);
    else setOpen(false);
  };

  useEffect(() => {
    checkUrl(history.location.pathname);
  }, []);

  history.listen((location) => {
    checkUrl(location.pathname);
  });

  return (
    <>
      <MenuCollapse
        navItemData={navItemData}
        open={open}
        handleToggle={handleToggle}
        canAccess={props?.canAccess}
      />
      <MenuChildren
        navItemData={navItemData}
        open={open}
        panelStatus={panelStatus}
        canAccess={props?.canAccess}
        userRole={userRole}
      />
    </>
  );
}

const isUrlInChildren = (parent: any, url: any) => {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i++) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (
      parent.children[i].link === url ||
      url.includes(parent.children[i].link)
    ) {
      return true;
    }
  }

  return false;
};

const MenuCollapse = (props: any) => {
  const styles = useStyles();
  const { navItemData, handleToggle, open ,canAccess } = props;
  return (
    <StyledListItemButton onClick={handleToggle} canAccess={canAccess} disabled={!canAccess} >
      <ListItemIcon
        sx={{ minWidth: "24px", minHeight: "24px", paddingRight: "18px" }}
      >
        {navItemData.icon ? <navItemData.icon sx={{ color: "white" }} /> : null}
      </ListItemIcon>
      <ListItemText primary={navItemData.name} className={styles.listItems} />
      <KeyboardArrowDown
        sx={{
          mr: -1,
          transform: open ? "rotate(-180deg)" : "rotate(0)",
          transition: "0.2s",
          ml: "20px",
        }}
      />
    </StyledListItemButton>
  );
};

const MenuChildren = (props: any) => {
  const { navItemData, open, panelStatus,canAccess,userRole } = props;
   const checkSubLevelAccess = (item, canAccess, section) => {
     if (section == "/configue") {
       return checkFeatureAccess(userRole, item?.user) && canAccess;
     } else {
       return canAccess;
     }
   };

  return (
    <Collapse in={open && panelStatus && canAccess} timeout="auto" unmountOnExit>
      {navItemData.children.map((item: any) => (
        <NavItem key={item.name} navItemData={item}  canAccess={checkSubLevelAccess(item,canAccess,navItemData?.link)} />
      ))}
    </Collapse>
  );
};
