import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useHistory } from "react-router-dom";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import {
  areArraysEqual,
  hasLengthGreaterThanZero,
  setReactFormValues,
} from "pages/configue/constants";
import {
  createCourseInitialValues,
  createCourseValidationSchema,
  createCourseProperties,
  createCourseMandatoryFields,
} from "./helper";
import configueService from "pages/configue/service";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";

export const CreateCourse = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createCourseInitialValues,
    createCourseValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleCreateCourse = async () => {
    const reqBody = {
      class_id: watch()?.class_id,
      display_name: watch()?.display_name,
      name: watch()?.name,
    };
    const res: any = await configueService.createCourseProperties(reqBody);
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "created successfully" }));
      history.replace(`/configue/create_course`);
    } else {
      dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  };

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Create New Course
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createCourseProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <ContentRight sx={{ mt: -7 }}>
          <Button
            variant="contained"
            sx={{
              width: "80px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
              marginLeft: "10px",
            }}
            onClick={handleCreateCourse}
            disabled={
              !isFieldsValidate(errors, createCourseMandatoryFields, getValues)
            }
          >
            create
          </Button>
        </ContentRight>
      </Paper>
    </div>
  );
};
