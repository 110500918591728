import { Button, Grid, Paper, Typography } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import configueService from "pages/configue/service";
import {
  areArraysEqual,
  hasLengthGreaterThanZero,
  setReactFormValues,
} from "pages/configue/constants";
import {
  createProgramInitialValues,
  createProgramValidationSchema,
  createProgramProperties,
  createProgramMandatoryFields,
} from "./helper";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useHistory } from "react-router-dom";

export const CreateProgram = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createProgramInitialValues,
    createProgramValidationSchema,
    ""
  );

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleCreateProgram = async () => {
    const reqBody = {
      name: watch()?.name,
    };
    const res: any = await configueService.createProgramProperties(reqBody);
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "created successfully" }));
      history.replace(`/configue/create_program`);
    } else {
      dispatch(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  };

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Create New Program
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createProgramProperties}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: -7 }}>
            <Button
              variant="contained"
              sx={{
                width: "80px",
                height: "40px",
                marginTop: "15px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleCreateProgram}
              disabled={
                !isFieldsValidate(
                  errors,
                  createProgramMandatoryFields,
                  getValues
                )
              }
            >
              create
            </Button>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
