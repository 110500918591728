import * as Yup from "yup";
import {
  urlValidationOptional,
  nameValidationOptional,
  nameValidationRequired,
} from "utils/form-validation";

export type createPrograme = {
  name: string;
};

export const createProgramInitialValues: createPrograme = {
  name: "",
};

export const createProgramMandatoryFields = [{ id: "name" }];

export const createProgramValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(`name required`)
    .min(1, "name is too short")
    .matches(nameValidationRequired, "Invalid name format")
    .max(100, "name is too long"),
});
export const createProgramProperties = [
  {
    id: "name",
    type: "text",
    label: `Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 4 },
    required: true,
  },
];

export const table = {
  primaryData: [
    {
      title: "Program Name",
      value: "program_name",
      type: "configue",
      width: "30%",
    },
    //   {
    //     title: "Program ID",
    //     value: "program_id",
    //     type: "configue",
    //     width: "20%",
    //   },

    {
      title: "Created At",
      value: "created_at",
      type: "configue",
    },
    {
      title: "Updated At",
      value: "updated_at",
      name: "update",
    },
    {
      title: "Updated By",
      value: "updated_username",
      name: "update",
      type: "configue",
    },
    {
      title: "Actions",
      width: "5%",
    },
  ],
  secondaryData: [
    {
      title: "created By",
      value: "created_username",
      type: "configue",
    },
  ],
};
