import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  successMsg: any;
  updateProgramDetails: any;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  successMsg: {},
  updateProgramDetails: {},
  filtersData: {},
};

const createProgramSlice = createSlice({
  name: "createProgram",
  initialState,
  reducers: {
    programListRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      // state.filtersData = action.payload;
    },
    programListSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    UpdateProgramDetails(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.updateProgramDetails = action.payload;
    },
    emptyProgramList(state: IInitState) {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  programListRequest,
  programListSuccess,
  UpdateProgramDetails,
  emptyProgramList,
} = createProgramSlice.actions;
export default createProgramSlice.reducer;
