import * as Yup from "yup";
export type createConfigType = {
  academic_year_list: string[]; //
  reservation_status_list: string[]; //
  class_list: string[]; //
  batch_list: string[]; //
  sub_batch_list: string[]; //
  platform_list: string[];
  college_list: string[]; //
  is_global_config: boolean | string;
  is_early_bird_config: boolean | string;
};

export const createConfigInitialValues: createConfigType = {
  academic_year_list: [],
  reservation_status_list: [],
  class_list: [],
  batch_list: [],
  sub_batch_list: [],
  platform_list: [],
  college_list: [],
  is_global_config: "false",
  is_early_bird_config: "false",
};

export const createConfigMandatoryFields = [
  { id: "academic_year_list" }, //
  { id: "reservation_status_list" }, //
  { id: "class_list" }, //
  { id: "batch_list" }, //
  { id: "platform_list" }, //
];

export const createConfigValidationSchema = Yup.object().shape({
  academic_year_list: Yup.array()
    .required("value required")
    .min(1, "value required"),
  reservation_status_list: Yup.array()
    .min(1, "value required")
    .required("value required"),
  // reservation_status_id:Yup.array().of(Yup.string()).required("value required"),
  class_list: Yup.array().min(1, "value required").required("value required"),
  batch_list: Yup.array().min(1, "value required").required("value required"),
  platform_list: Yup.array()
    .min(1, "value required")
    .required("value required"),
});

export const createConfigProperties = (watch) => {
  return [
    {
      id: "academic_year_list",
      type: "multi-select",
      label: "Academic Year * ",
      className: "select-styles",
      required: true,
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      list: "academic_year_list",
      keyValue: { label: "year_name", value: "year_id" },
    },
    {
      id: "reservation_status_list",
      type: "multi-select",
      label: "Registration Status *",
      required: true,
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      list: "reservation_status_list",
      keyValue: {
        label: "registration_status_name",
        value: "registration_status_id",
      },
    },
    {
      id: "class_list",
      type: "multi-select-dependency",
      label: "Class *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      required: true,
      list: "class_course_batch_data",
      keyValue: { label: "class_name", value: "class_id" },
      dependentFields: ["batch_list", "sub_batch_list"],
      dependentFlds: {
        class_course_batch_data: "class_id",
      },
    },
    {
      id: "batch_list",
      type: "multi-select-dependency",
      label: "Batch *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      isDependent: true,
      options: [],
      required: true,
      list: "batch_list",
      dependencyLabel: "Please select Class",
      keyValue: { label: "batch_name", value: "batch_id" },
      fieldKeyLists: {},
      dependentFields: ["sub_batch_list"],
      dependentFlds: {
        batch_list: "batch_id",
      },
    },
    {
      id: "sub_batch_list",
      type: "multi-select-dependency",
      label: "Sub Batch",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: [],
      isDependent: true,
      required: true,
      dependencyLabel: "Please fill class and batch",
      fieldKeyLists: {},
      list: "sub_batch_list",
      keyValue: { label: "sub_batch_name", value: "sub_batch_id" },
    },
    {
      id: "college_list",
      type: "multi-select-dependency",
      label: "College",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      isDependent: true,
      options: [],
      list: "college_list",
      dependencyLabel: "Please select State",
      keyValue: { label: "college_name", value: "college_id" },
      fieldKeyLists: {},
    },
    {
      id: "platform_list",
      type: "multi-select-dependency",
      label: "Platform *",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      // isDependent: true,
      options: [],
      list: "platform_list",
      dependencyLabel: "Please select State",
      keyValue: { label: "label", value: "value" },
      fieldKeyLists: {},
    },
    {
      id: "is_global_config",
      type: "normal-select",
      label: "is Global Config",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
      hideNone: true,
    },
    {
      id: "is_early_bird_config",
      type: "normal-select",
      label: "is Early Bird Config",
      className: "select-styles",
      breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
      readOnly: watch.is_global_config === "true" ? true : false,
      hideNone: true,
    },
  ];
};
// academic_year_list: watch()?.academic_year_list,
// registration_status_list: watch()?.reservation_status_list,
// class_list: watch()?.class_list,
// batch_list: watch()?.batch_list,
// sub_batch_list: watch()?.sub_batch_list,
// college_list: watch()?.college_list,
// // platform_list: watch()?.platform_list,
// platform_list: ["nlearn", "ekidz", "ndigital"],
// is_global_config: watch()?.is_global_config == "true" ? true : false,
// is_early_bird_config:
//   watch()?.is_early_bird_config == "true" ? true : false,
// config_json: jsonData,
export const table = {
  primaryData: [
    {
      title: "Platform",
      value: "platform_list",
      //name: "date_time",
      type: "configue",
    },
    {
      title: "academic year",
      value: "academic_year_list",
      // name: "academic_year_name",
      type: "configue",
    },
    {
      title: "class",
      value: "class_list",
      type: "configue",
    },
    {
      title: "college",
      value: "college_list",
      type: "configue",
    },
    {
      title: "batch",
      value: "batch_list",
      type: "configue",
    },
    {
      title: "Actions",
      width: "5%",
    },
  ],
  secondaryData: [
    {
      title: "Registration",
      value: "registration_status_list",
    },
    {
      title: "is_early_bird_config",
      value: "is_early_bird_config",
    },
    {
      title: "sub Batch",
      value: "sub_batch_list",
    },
    {
      title: "Created At",
      value: "created_at",
      type: "configue",
    },
    {
      title: "created By",
      value: "created_username",
      type: "configue",
    },
    {
      title: "Updated At",
      value: "updated_at",
      name: "update",
    },
    {
      title: "Updated By",
      value: "updated_username",
      name: "update",
    },
  ],
};
