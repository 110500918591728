import EditIcon from "@mui/icons-material/EditOutlined";
import { Stack, Tooltip } from "@mui/material";

type ButtonProps = {
  handleEdit?: () => void;
  hideEdit?:boolean
};

export const EditButtonTable = (props: ButtonProps) => {
  const { handleEdit,hideEdit} = props;
  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ cursor: "pointer" }}
    >
      {hideEdit !== true ?(
        <Tooltip title="Edit Delivery">
          <EditIcon onClick={handleEdit} />
        </Tooltip>
      ):"-"}
    </Stack>
  );
};
