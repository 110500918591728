import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import configueService from "pages/configue/service";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import {
  areArraysEqual,
  hasLengthGreaterThanZero,
  setReactFormValues,
} from "pages/configue/constants";
import {
  createProgramInitialValues,
  createProgramValidationSchema,
  createProgramProperties,
  createProgramMandatoryFields,
  table,
} from "./helper";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { Link, useHistory } from "react-router-dom";
import { TypographyHeading } from "shared/molecules/typography-layout";
import Loader from "pages/build-test/loader";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import NoDataFound from "shared/molecules/no-data";
import {
  programListRequest,
  UpdateProgramDetails,
} from "pages/configue/slices/create-program-slice";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn: {
    backgroundColor: "#3f50b5",
    color: "white",
    width: "90px",
  },
  BtnWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export const CreateProgramMain = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createProgramInitialValues,
    createProgramValidationSchema,
    ""
  );
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const { data, error, loading } = useAppSelector(
    (state) => state.createProgramSlice
  );

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleEdit = (singleProgram) => {
    dispatch(UpdateProgramDetails(singleProgram));
    history.push(`/configue/create_program/edit/${singleProgram?.program_id}`);
  };

  useEffect(() => {
    dispatch(programListRequest({ limit, offset }));
  }, [limit, offset]);

  return (
    <div>
      <ContentRight>
        <Link to="/configue/create_program/create" className={classes.label}>
          <Button variant="contained">Create New Program </Button>
        </Link>
      </ContentRight>
      <TypographyHeading>Program</TypographyHeading>
      <Paper elevation={2} sx={{ px: 2, pb: 2, pt: 1 }}>
        <Box sx={{ mt: 2 }}>
          {loading && <Loader />}
          {!loading && data.length > 0 && !error ? (
            <div>
              <CollapsibleTable
                data={data}
                table={table}
                id="id"
                handleEdit={handleEdit}
                // handleCopy={handleCopy}
                rowsPerPageCount={20}
                paginationSegmentApi="false"
                hasPageApiCall={false}
                showPaginationFooter={false}
              />
            </div>
          ) : (
            <div>
              {!loading && (
                <NoDataFound message="Please Search for Programs List" />
              )}
            </div>
          )}
        </Box>
        <Box className={classes.BtnWrapper}>
          <Button
            onClick={() => {
              setOffset(offset - 10);
            }}
            className={classes.Btn}
            disabled={offset == 0}
            variant="contained"
            size="medium"
          >
            prev
          </Button>
          <Button
            onClick={() => {
              setOffset(offset + 10);
            }}
            className={classes.Btn}
            disabled={data.length <= 9}
            variant="contained"
            size="medium"
          >
            next
          </Button>
        </Box>
      </Paper>
    </div>
  );
};
