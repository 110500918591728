import { ForceUpdate } from "./routes/force-update";
import { UpdateVersion } from "./routes/force-update/UpdateVersion";
import { StudentCourseLookUp } from "./routes/student-Course-lookup";
import { CreateStdCourse } from "./routes/student-Course-lookup/CreateStudentCourse";
import { FeatureConfigue } from "./routes/feature-configue";
import { CreateConfig } from "./routes/feature-configue/CreateConfig";
import { StudentProgramLookUp } from "./routes/student-program-lookup";
import { CreateStdProgram } from "./routes/student-program-lookup/CreateStudentProgram";
import { UpdateStdProgram } from "./routes/student-program-lookup/UpdateStudentProgram";
import { UpdateConfig } from "./routes/feature-configue/UpdateConfig";
import { UpdateStdCourse } from "./routes/student-Course-lookup/UpdateStudentCourse";
import { CreateCourseMain } from "./routes/create-course";
import { CreateProgramMain } from "./routes/create-program";
import { CreateCourse } from "./routes/create-course/create";
import { UpdateCourse } from "./routes/create-course/update";
import { CreateProgram } from "./routes/create-program/create";
import { UpdateProgram } from "./routes/create-program/update";
import { plateformConfigueUser } from "utils/role-restriction/helper";

export const platformConfigueRoutes = [
  {
    exact: true,
    path: "/force_update",
    component: ForceUpdate,
    user: [
      ...plateformConfigueUser?.FORCE_UPDATE_READ,
      ...plateformConfigueUser?.FORCE_UPDATE_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/force_update/edit/:version_id",
    component: UpdateVersion,
    user: [...plateformConfigueUser?.FORCE_UPDATE_READ_WRITE],
  },
  {
    exact: true,
    path: "/feature_configue",
    component: FeatureConfigue,
    user: [
      ...plateformConfigueUser?.FEATURE_CONFIGUE_READ,
      ...plateformConfigueUser?.FEATURE_CONFIGUE_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/feature_configue/create",
    component: CreateConfig,
    user: [...plateformConfigueUser?.FEATURE_CONFIGUE_READ_WRITE],
  },
  {
    exact: true,
    path: "/feature_configue/edit/:config_id",
    component: UpdateConfig,
    user: [...plateformConfigueUser?.FEATURE_CONFIGUE_READ_WRITE],
  },
  {
    exact: true,
    path: "/feature_configue/duplicate/:config_id",
    component: UpdateConfig,
    user: [...plateformConfigueUser?.FEATURE_CONFIGUE_READ_WRITE],
  },
  {
    exact: true,
    path: "/student_course_lookup",
    component: StudentCourseLookUp,
    user: [
      ...plateformConfigueUser?.STD_COURSE_LU_READ,
      ...plateformConfigueUser?.STD_COURSE_LU_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/student_course_lookup/create",
    component: CreateStdCourse,
    user: [...plateformConfigueUser?.STD_COURSE_LU_READ_WRITE],
  },
  {
    exact: true,
    path: "/student_course_lookup/duplicate/:course_id",
    component: UpdateStdCourse,
    user: [...plateformConfigueUser?.STD_COURSE_LU_READ_WRITE],
  },
  {
    exact: true,
    path: "/student_course_lookup/edit/:course_id",
    component: UpdateStdCourse,
    user: [...plateformConfigueUser?.STD_COURSE_LU_READ_WRITE],
  },
  {
    exact: true,
    path: "/student_program_lookup",
    component: StudentProgramLookUp,
    user: [
      ...plateformConfigueUser?.STD_PROGRAM_LU_READ,
      ...plateformConfigueUser?.STD_PROGRAM_LU_READ_WRITE,
    ],
  },
  {
    exact: true,
    path: "/student_program_lookup/duplicate/:std_program_id",
    component: UpdateStdProgram,
    user: [...plateformConfigueUser?.STD_PROGRAM_LU_READ_WRITE],
  },
  {
    exact: true,
    path: "/student_program_lookup/edit/:std_program_id",
    component: UpdateStdProgram,
    user: [...plateformConfigueUser?.STD_PROGRAM_LU_READ_WRITE],
  },
  {
    exact: true,
    path: "/student_program_lookup/create",
    component: CreateStdProgram,
    user: [...plateformConfigueUser?.STD_PROGRAM_LU_READ_WRITE],
  },
  {
    exact: true,
    path: "/create_course",
    component: CreateCourseMain,
    user: [...plateformConfigueUser?.CREATE_COURSE_READ_WRITE],
  },
  {
    exact: true,
    path: "/create_course/create",
    component: CreateCourse,
    user: [...plateformConfigueUser?.CREATE_COURSE_READ_WRITE],
  },
  {
    exact: true,
    path: "/create_course/edit/:course_id",
    component: UpdateCourse,
    user: [...plateformConfigueUser?.CREATE_COURSE_READ_WRITE],
  },
  {
    exact: true,
    path: "/create_program",
    component: CreateProgramMain,
    user: [...plateformConfigueUser?.CREATE_PROGRAM_READ_WRITE],
  },
  {
    exact: true,
    path: "/create_program/create",
    component: CreateProgram,
    user: [...plateformConfigueUser?.CREATE_PROGRAM_READ_WRITE],
  },
  {
    exact: true,
    path: "/create_program/edit/:program_id",
    component: UpdateProgram,
    user: [...plateformConfigueUser?.CREATE_PROGRAM_READ_WRITE],
  },
];
