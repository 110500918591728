import * as Yup from "yup";
import {
  urlValidationOptional,
  nameValidationOptional,
  nameValidationRequired,
} from "utils/form-validation";

export type createCourse = {
  display_name: string;
  name: string;
  class_id: string;
};

export type searchCourse = {
  class_id: string;
};

export const createCourseInitialValues: createCourse = {
  display_name: "",
  name: "",
  class_id: "",
};

export const searchCourseInitialValues: searchCourse = {
  class_id: "",
};

export const createCourseMandatoryFields = [
  { id: "display_name" },
  { id: "name" },
  { id: "class_id" },
];

export const searchCourseMandatoryFields = [{ id: "class_id" }];

export const createCourseValidationSchema = Yup.object().shape({
  display_name: Yup.string()
    .required(`Display Name required`)
    .min(1, "Display Name is too short")
    .matches(nameValidationRequired, "Invalid name format")
    .max(100, "Display Name is too long"),
  name: Yup.string()
    .required(`name required`)
    .min(1, "name is too short")
    .matches(nameValidationRequired, "Invalid name format")
    .max(100, "name is too long"),
  class_id: Yup.string().required("class_id is required"),
});

export const searchCourseValidationSchema = Yup.object().shape({
  class_id: Yup.string().required("class_id is required"),
});

export const createCourseProperties = [
  {
    id: "name",
    type: "text",
    label: `Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 4 },
    required: true,
  },
  {
    id: "display_name",
    type: "text",
    label: `Display Name`,
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3.8 },
    required: true,
  },
  {
    id: "class_id",
    type: "single-select-dependency",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    required: true,
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
  },
];
export const searchCourseProperties = [
  {
    id: "class_id",
    type: "single-select-dependency",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    required: true,
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
  },
];
// {
//   "course_id": "F9eQT4Z12t",
//   "course_name": "VIVA-TS-STATE E-KIDZ-PP2 COURSE",
//   "display_name": "VIVA-TS-STATE",
//   "created_at": "13-09-2023 22:49:34",
//   "updated_at": "13-09-2023 22:49:34",
//   "created_by": null,
//   "created_username": null,
//   "updated_by": null,
//   "updated_username": null,
//   "class_list": [
//       {
//           "class_id": "N0buYjPldw",
//           "class_name": "E-KIDZ-PP2"
//       }
//   ]
// },

export const table = {
  primaryData: [
    {
      title: "Course Name",
      value: "course_name",
      type: "configue",
      width: "20%",
    },
    {
      title: "Display Name",
      value: "display_name",
      type: "configue",
      width: "20%",
    },
    {
      title: "Class",
      value: "class_name",
      type: "configue",
    },
    {
      title: "Created At",
      value: "created_at",
      type: "configue",
    },
    {
      title: "Updated At",
      value: "updated_at",
      name: "update",
    },
    {
      title: "Actions",
      width: "5%",
    },
  ],
  secondaryData: [
    {
      title: "created By",
      value: "created_username",
      type: "configue",
    },
    {
      title: "Updated By",
      value: "updated_username",
      name: "update",
      type: "configue",
    },
  ],
};
