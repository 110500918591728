import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  successMsg: any;
  updateStudentProgramDetails: any;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  successMsg: {},
  updateStudentProgramDetails: {},
  filtersData: {},
};

const studentProgramSlice = createSlice({
  name: "studentProgram",
  initialState,
  reducers: {
    StudentProgramLookupRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      // state.filtersData = action.payload;
    },
    StudentProgramLookupSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
    UpdateSingleStdProgramDetails(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.updateStudentProgramDetails = action.payload;
    },
    emptyProgramData(state: IInitState) {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  StudentProgramLookupRequest,
  StudentProgramLookupSuccess,
  UpdateSingleStdProgramDetails,
  emptyProgramData,
} = studentProgramSlice.actions;
export default studentProgramSlice.reducer;
